<template>
    <div class="content-wrapper">
        <bo-page-title></bo-page-title>
        <div class="content pt-0">
            <div class="card">
                <div class="card-header bg-white">
                    <div class="row align-items-center">
                        <div class="col-md-5">
                            <div class="form-row align-items-center">
                                <div class="col-md-4">
                                    <h5 class="card-title font-weight-semibold">Daftar Laporan</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-7 col-lg-8">
                            <div class="row gx-1">
                                <div class="col-md-auto">
                                    <div class="form-group mb-0 d-flex">
                                            <date-range-picker
                                                    ref="picker"
                                                    :locale-data="datePickerConfig.locale"
                                                    :autoApply="datePickerConfig.autoApply"
                                                    v-model="dateRange"
                                                    :opens="'right'"
                                                    :ranges="datePickerConfig.ranges"
                                                    @update="updateValues"
                                            >
                                                    <template v-slot:input="picker">
                                                            {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                                    </template>
                                            </date-range-picker>
                                            <div class="input-group-append calendar-group">
                                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-info mt-2 mb-0">Laporan yang diunduh akan sesuai dengan tanggal yang telah
                        dipilih</div>
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nama Laporan</th>
                                <th>Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <div class="display-c" v-for="(v,k) in (dataReport||[])" :key="k">
                                <tr class="table-secondary">
                                    <th colspan="99" class="font-weight-semibold">{{v.head||"-"}}</th>
                                </tr>
                                <tr v-for="(v1,k1) in (v.data||[])" :key="k1">
                                    <td>{{k1+1}}</td>
                                    <td>{{v1.name||"-"}}</td>
                                    <td>
                                            <a v-if="v1.slug" @click="downloadLaporan(v1)" href="javascript:;" data-popup="tooltip" v-b-tooltip.hover title="Unduh Laporan"
                                            class="btn btn-icon rounded-round border-success alpha-success"><i class="icon-download"></i></a>
                                            <span v-else> - </span>
                                    </td>
                                </tr>
                            </div>
                            <tr v-if="!(dataReport||[]).length"> 
                            <td colspan="99" class="text-center"> No Data
                            </td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
		
        <b-modal 
            v-model="loadingReport"
			hide-footer
			hide-header
			size="sm"
			no-close-on-backdrop
			class="text-center"
        >
			<div class="d-block text-center p-3 pt-3">
				<b-spinner style="width: 3rem; height: 3rem;" variant="info" label="Large Spinner"></b-spinner>
				<h5 class="text-info">Sedang Melakukan Download Laporan ....</h5>
				<small>*Pengunduhan Laporan akan terjadi dalam waktu beberapa saat</small>
			</div>
		</b-modal>
    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')
import _ from 'lodash'
import $ from 'jquery'


export default {
	extends: GlobalVue,
	data() {
		return {
			loadingReport: false,
			dateRange: {
				startDate: new Date(),
				endDate: new Date(),
			},
			datePickerConfig: {
				startDate: new Date(),
				endDate: new Date(),
				autoApply: true,
				ranges: {
						'Hari Ini': [new Date(), new Date()],
						'7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
						'30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
				},
				applyClass: 'btn-sm btn-primary',
				cancelClass: 'btn-sm btn-light',
				locale: {
						applyLabel: 'Terapkan',
						cancelLabel: 'Batal',
						direction: 'ltr',
						format: 'mm/dd/yyyy',
						separator: ' - ',
				}
			},
			dataReport: [
				{
					head : "Laporan Fisioterapi",
					type: "LF",
					data : [
						{no : 1, name: "Jumlah Pasien Berdasarkan Jaminan", slug: "pasienByJaminan"},
						{no : 2, name: "Jumlah Pasien Berdasarkan Jenis Kelamin", slug: "pasienByJenisKelamin"},
						{no : 3, name: "Jumlah Pasien Berdasarkan Status Kunjungan", slug: "pasienByStatusKunjungan"}
                    ]
                }
			]
		}
	},
	components:{DateRangePicker},
	mounted() {
		let filterColumn = JSON.parse(localStorage.getItem(`kolumn_management_${this.user.id}`) || '[]')
		if(filterColumn.length){
			this.selectedFilter = filterColumn
			this.acceptedFilter = filterColumn
		}
		setTimeout(()=>{
			this.filter.typeLaporan = 'ALL'
		},300)
	},
	methods: {
		doResetData(){
			this.filter.search = ''
			const oriData = [
				{
					head : "Laporan Fisioterapi",
					type: "LF",
					data : [
						{no : 1, name: "Jumlah Pasien Berdasarkan Jaminan", slug: "pasienByJaminan"},
						{no : 2, name: "Jumlah Pasien Berdasarkan Jenis Kelamin", slug: "pasienByJenisKelamin"},
						{no : 3, name: "Jumlah Pasien Berdasarkan Status Kunjungan", slug: "pasienByStatusKunjungan"}
                    ]
                }
			]
			this.dataReport = oriData
		},
		doSearch(){
			const oriData = [
				{
					head : "Laporan Fisioterapi",
					type: "LF",
					data : [
						{no : 1, name: "Jumlah Pasien Berdasarkan Jaminan", slug: "pasienByJaminan"},
						{no : 2, name: "Jumlah Pasien Berdasarkan Jenis Kelamin", slug: "pasienByJenisKelamin"},
						{no : 3, name: "Jumlah Pasien Berdasarkan Status Kunjungan", slug: "pasienByStatusKunjungan"}
                    ]
                }
			]
			let search = this.filter.search
			
			let data = _.filter(oriData, v => {
				 v.data = _.filter(v.data, v2 => {
					 return _.includes(v2.name, search.toUpperCase())
				 })
				 if((v.data||[]).length){
					 return v
				 }
			})

			this.dataReport = data
		},
		doFill(){
			const oriData = [
				{
					head : "Laporan Fisioterapi",
					type: "LF",
					data : [
						{no : 1, name: "Jumlah Pasien Berdasarkan Jaminan", slug: "pasienByJaminan"},
						{no : 2, name: "Jumlah Pasien Berdasarkan Jenis Kelamin", slug: "pasienByJenisKelamin"},
						{no : 3, name: "Jumlah Pasien Berdasarkan Status Kunjungan", slug: "pasienByStatusKunjungan"}
                    ]
                }
			]
			if(this.filter.typeLaporan != 'ALL'){
				let data = 
				_.filter(oriData, v => {
					return v.type == this.filter.typeLaporan
				})
				this.dataReport = data
			}else{
				this.doResetData()
			}
		},
		momentDate(dates){
				let formatted = moment(dates).format("DD MMM YYYY")
				return formatted
		},
		updateValues(e){
				this.doConvertDate()
		},  
		doConvertDate(){
				this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
				this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
		},
		
        to24Hours(date){
            return moment(date).add(24, 'hours').format('YYYY-MM-DD')
        },

		downloadLaporan(v){
			this.doConvertDate()
			this.loadingReport = true
			
			let data = {
				exptype: 'xlsx', type: "export", startDate: this.filter.startDate, endDate: this.filter.endDate
			}

			let self = this
			$.ajax({
					type: "POST",
					url: process.env.VUE_APP_API_URL + `/report-fisio/${v.slug}?token=IXs1029102asoaksoas102901290`,
					data: data,
					cache: false,
					xhrFields:{
							responseType: 'blob'
					},
					success: data => 
					{
							self.loadingOverlay = false
							self.loadingReport = false

							var link = document.createElement('a')
							link.href = window.URL.createObjectURL(data)
							link.download = `${v.name}-${moment().format("YYYY-MM-DD")}.xlsx`
							link.click()
					},
					fail: data => {
							self.loadingOverlay = false
							alert('Not downloaded')
					}
			})
		}
	},
	filters: {
			date(val) {
					return val ? moment(val).format("D MMM YYYY") : ""
			}
	}
}

</script>

<style lang="scss" scoped>
  .custom-checkbox{
    margin-bottom: 5px !important;
  }
</style>